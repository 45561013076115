export default {
  methods: {
    $_status_list() {
      return [
        {
          slug: "0",
          name: "send",
          ru: "Отгружен",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "0-0",
          name: "Отгружен",
          ru: "Отгружен",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "orders",
        },
        {
          slug: "1",
          name: "in_process",
          ru: "В пути",
          color: "var(--secondary-color)",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "2",
          name: "accepted",
          ru: "Принят",
          color: null,
          colorText: null,
          type: "routes",
        },
        {
          slug: "3",
          name: "stopped",
          ru: "Остановлен",
          color: null,
          colorText: null,
          type: "routes",
        },
        {
          slug: "4",
          name: "finished",
          ru: "Завершен",
          color: "#283039",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "5",
          name: "declined",
          ru: "Отклонен",
          color: "#283039",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "5-0",
          name: "Отменен",
          ru: "Отменен",
          color: "#283039",
          colorText: "#FFFFFF",
          type: "orders",
        },
      ];
    },
    $_status_getForRoutes() {
      return this.$_status_list()
        .filter((value) => value.type === "routes")
        .sort((a, b) => {
          const textA = a.ru.toUpperCase();
          const textB = b.ru.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    },
    statusChange(name) {
      const filtered = this.$_status_list().filter((value) => value.name === name);
      if (filtered.length > 0 && filtered[0].ru) {
        return filtered[0].ru;
      } else return (name[0].toUpperCase() + name.slice(1)).replace(/_/g, " ");
    },
    statusColor(name) {

      const filtered = this.$_status_list().filter((value) => value.name === name);
      if (filtered.length > 0 && filtered[0].color) {
        return filtered[0].color;
      } else return "#EBEEF2";
    },
    statusColorText(name) {
      const filtered = this.$_status_list().filter((value) => value.name === name);
      if (filtered.length > 0 && filtered[0].colorText) {
        return filtered[0].colorText;
      } else return "#000000";
    },
  },
};
